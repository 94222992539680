import $ from 'jquery';

export function backToTop() {
    $('.back-to-top').click( () => {
        const body = $("html, body");
        body.stop().animate({scrollTop:0}, 500);
    })
}

export function detectmob() {
    if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ){
        return true;
    }
    else {
        return false;
    }
}

export function detectmobByWindowSize() {
    if($(window).width() <= 767) return true;
    else return false;
}


export function transitionEnd(element, callbackFunc) {
    const transitionEvent = whichTransitionEvent();
    if(transitionEvent === undefined) {
        /**
         * Fallback IE9
         */
        if (callbackFunc) {
            callbackFunc.apply();
        }
        return;
    }
    element.unbind('webkitTransitionEnd transitionend msTransitionEnd oTransitionEnd');
    element.bind('webkitTransitionEnd transitionend msTransitionEnd oTransitionEnd', () => {
        element.unbind('webkitTransitionEnd transitionend msTransitionEnd oTransitionEnd');
        if (callbackFunc) {
            callbackFunc.apply();
        }
    });
}

export function animationEnd(element, callbackFunc) {
    element.unbind('animationend webkitAnimationEnd MSAnimationEnd oanimationend');
    element.bind('animationend webkitAnimationEnd MSAnimationEnd oanimationend', () => {
        element.unbind('animationend webkitAnimationEnd MSAnimationEnd oanimationend');
        if (callbackFunc) {
            callbackFunc.apply();
        }
    });
}

function whichTransitionEvent(){
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
        'transition':'transitionend',
        'OTransition':'oTransitionEnd',
        'MozTransition':'transitionend',
        'WebkitTransition':'webkitTransitionEnd'
    }

    for(t in transitions){
        if( el.style[t] !== undefined ){
            return transitions[t];
        }
    }
}
