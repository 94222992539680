import $ from 'jquery';

export class Searchbar {
    constructor() {
        this.header = $('#header');
        this.searchButton = $('#search-btn');
        this.searchBar = $('.search-bar');
        this.removeBtn = $(this.searchBar).find('.remove-btn');
        this.onSearchBtnToggle();
        this.onRemoveBnClick();
    }

    onSearchBtnToggle() {
        if($('.section').hasClass('search-result-page')) {
            this.header.addClass('open');
            this.searchBar.addClass('active');
            return;
        }
        this.searchButton.click( () => {
            if(this.searchBar.hasClass('active')) {
                this.header.removeClass('open');
                this.searchButton.removeClass('active');
                this.searchBar.removeClass('active');
            }else {
                this.header.addClass('open');
                this.searchButton.addClass('active');

                this.searchBar.css('display', 'block');
                setTimeout( () => {
                    this.searchBar.addClass('active');
                },100);

            }
        })
    }

    onRemoveBnClick() {
        this.removeBtn.click( () => {
            const input = $("input[name='search-text']");
            input.val('')
        });
    }

}