import $ from 'jquery';

export class Consulting {
    constructor() {
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 83
            }, 500);
        });
    }
}