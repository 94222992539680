import { FilterMenu } from "./filter/FilterMenu";
import { FilterBar } from "./filter/FilterBar";

export class Research {
    constructor() {
        const filterMenu = new FilterMenu();
        const filterBar = new FilterBar();

        filterBar.on('onClickCloseBtn', () => {
            filterMenu.closeBar();
        })
    }
}