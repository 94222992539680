import $ from 'jquery';
import { FilterBarSticky } from "./research/filter/FilterBarSticky";

export class ScrollEvents {
    constructor() {
        const filterBarSticky = new FilterBarSticky();
        $(window).scroll( (event)  => {
            const windowTop = $(window).scrollTop();
            filterBarSticky.onStick(windowTop);
        });
    }



}