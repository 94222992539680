import $ from 'jquery';
import { detectmob, detectmobByWindowSize } from "./../../../shared";

export class FilterBarSticky {
    constructor() {
        this.scrollTop = 0;
        this.detectmob = detectmob();
        this.init = false;

        if($('.research.wrapper').length > 0) {
            this.init = true;
            this.stickTop = $('.research.wrapper').offset().top - $('.filterBar-menu-container').height();
            this.stickyParent = $('.research .views-container').find('.right-column');

            this.stickyChild = $('.filter-bar');
        }
    }

    onStick(windowScrollTop) {
        if(this.init) {
            if(this.detectmob || detectmobByWindowSize()) {
                if (windowScrollTop > this.stickTop) {
                    this.stickyParent.addClass('fixed');
                    this.stickyChildTop = $('#header').height() + $('.filterBar-menu-container').height() + 1;
                    //When the column has the position 'fix', the top must be 0, otherwise cannot scroll to the bottom of its contents.
                    //Solution : move its child (filter-bar) down so it's positioned after the header and the filter bar
                    this.stickyChild.css('top',  this.stickyChildTop);
                } else {
                    this.stickyParent.removeClass('fixed');
                    this.stickyChild.css('top', 0);
                }
            }else {
                this.stickyParent.removeClass('fixed');
                this.stickyChild.css('top', 0);
            }
        }
    }

}