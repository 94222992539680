import $ from 'jquery';
import 'slick-carousel';

export class Hero {
    constructor() {
        if($('.hero__gallery').length > 0) {
            $('.hero__gallery').slick({
                dots: true,
                arrows : false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 10000,
            });
        }

    }
}