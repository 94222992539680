import $ from 'jquery';
import { team } from './../data/Team';
import { transitionEnd } from './../../shared';

export class TeamPanel {
    constructor() {
        if($('.team').length > 0) {
            this.initFounder();
            this.initVicePresident();
            this.onClickOpen();
            this.onClickClose();
        }

    }

    initFounder() {
        const founder = team.map( (staff, i) => {
            return ( i < 2) ? (
                `<div class="col col-xs-6 col-xm-6 col-sm-6 col-md-6 col-lg-6">` +
                    `<div class="team-people" data-team-id="${i}">` +
                        `<div class="avatar-mask">` +
                            `<div class="avatar"><img src="../assets/images/team/${staff.image}"></div>` +
                        `</div>` +
                        `<div class="name">${staff.name}</div>` +
                        `<div class="position">${staff.position}</div>` +
                    `</div>` +
                `</div>`
            ): null;
        });

        $('.team').find('.founder').html(founder.join(''));
    }

    initVicePresident() {
        const vp = team.map( (staff, i) => {
            return ( i >= 2) ? (
                `<div class="col col-xs-6 col-xm-6 col-sm-3 col-md-3 col-lg-3">` +
                `<div class="team-people vp" data-team-id="${i}">` +
                `<div class="avatar-mask">` +
                `<div class="avatar"><img src="../assets/images/team/${staff.image}"></div>` +
                `</div>` +
                `<div class="name">${staff.name}</div>` +
                `<div class="position">${staff.position}</div>` +
                `</div>` +
                `</div>`
            ): null;
        });
        $('.team').find('.vice-president').html(vp.join(''));
    }

    onClickOpen() {
        const that = this;
        $('.team-people').each( function(){
            $(this).click( function() {
                that.updateModal($(this).data('team-id'));
            })
        })
    }

    updateModal(teamId) {
        const member = team[teamId];
        const modal = $('.modal');
        const image = modal.find('.avatar');
        const name = modal.find('.name');
        const position = modal.find('.position');
        const headline = modal.find('.headline');
        const subhead = modal.find('.list');
        const email = modal.find('.contact-list').find('.email');
        const linkedIn = modal.find('.contact-list').find('.username');

        const list = (member.content.length > 0)? `<ul>${member.content.map( (point) => `<li>${point}</li>`).join('')}</ul>`:null;

        image.html(`<img src=../assets/images/team/${member.image} />`);
        name.html(member.name);
        position.html(member.position);
        headline.html(member.header);
        subhead.html(member.subheader + list);
        email.html(`<a href="mailto:${member.email}">${member.email}</a>`);
        linkedIn.html(member.social);

        this.showModal();
        this.showOverlay();
    }

    showModal() {
        const modal = $('.modal');
        modal.css('display','table');
        setTimeout( () => {
            modal.addClass('active');
        }, 100);

        const panel = $('.section.team');
        $('html, body').animate({
            scrollTop: panel.offset().top - parseInt(panel.css('padding-top'))
        }, 500);
    }

    onClickClose() {
        const modal = $('.modal');
        const closeBtn = modal.find('.close-btn');
        closeBtn.click( () => {
            modal.removeClass('active');
            transitionEnd(modal, () => {
                modal.css('display','none');
            });

            this.hideOverlay();
        });
    }

    showOverlay() {
        const overlay = $('.overlay');
        overlay.css('display','block');
        setTimeout( () => {
            overlay.addClass('active');
        }, 100);
    }

    hideOverlay() {
        const overlay = $('.overlay');
        overlay.removeClass('active');
        transitionEnd(overlay, () => {
            overlay.css('display','none');
        })
    }

}