import $ from 'jquery';

export class BrandSlider {
    constructor() {
        if($('.section.brands').length > 0) {
            $('.section.brands').find('.blocks').slick({
                rows: 2,
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: true,
                arrows : false,
                autoplay: true,
                autoplaySpeed: 3000,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            });
        }

    }
}