import $ from "jquery";
import {transitionEnd} from "../../../shared";

const gmaps = ['<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.8733752719354!2d114.15287071548022!3d22.28278594916019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404007b5a985965%3A0xf5ffff7fba5a84dc!2sWorld+Trust+Tower%2C+50+Stanley+St%2C+Central!5e0!3m2!1sen!2shk!4v1543432601919" width="100%" height="600" frameborder="0" style="border:0" allowfullscreen></iframe>',
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.1276152703017!2d72.86284891545039!3d19.05812595748519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf2f651bffff%3A0x3577721ee462e107!2sWeWork!5e0!3m2!1sen!2shk!4v1543432226688" width="100%" height="600" frameborder="0" style="border:0" allowfullscreen></iframe>',
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8176702836554!2d103.84747101540147!3d1.2832499621416809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190c10a2dca3%3A0xd17e06f5701c6df7!2s3+Church+St%2C+25+Samsung+Hub%2C+Singapore+049483!5e0!3m2!1sen!2shk!4v1543432178397" width="100%" height="600" frameborder="0" style="border:0" allowfullscreen></iframe>'

];

export class Map {
    constructor() {

        this.mapBtn = null;
        this.overlay = $('.overlay');
        this.closeMapBtn = null;

        const that = this;

        if($('#map-btn').length > 0) {
            this.mapBtn = $('.map-btn');
            this.closeMapBtn = $('.close-map-btn');

            this.mapBtn.each( function(index) {
                $(this).click( () => {
                    that.showOverlay();
                    that.showMap(index);
                });
            });



            this.closeMapBtn.click( () => {
                this.hideOverlay();
                this.hideMap();
            })
        }
    }

    showOverlay() {
        const overlay = $('.overlay');
        overlay.css('display','block');
        setTimeout( () => {
            overlay.addClass('active');
        }, 100);
    }

    hideOverlay() {
        const overlay = $('.overlay');
        overlay.removeClass('active');
        transitionEnd(overlay, () => {
            overlay.css('display','none');
        })
    }

    showMap(id) {
        $('.map-wrapper').html(gmaps[id]);
        $('.google-map').css('display','block');
    }

    hideMap() {
        $('.google-map').css('display','none');
    }
}