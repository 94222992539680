import { detectmob, detectmobByWindowSize } from "../shared";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
const fixTo = require('fixto/dist/fixto');


export class Sticky {
    constructor() {
        const isMobile = detectmob() || detectmobByWindowSize();

        /* filter bar menu in research page on mobile*/
        if(isMobile) {
            if($('.filterBar-menu-container').length > 0) {
                $('.filterBar-menu-container').fixTo('.research', {
                    mind: '#header',
                    zIndex: 2,
                    top: 0
                });
            }
        }else {
            if($('.sidebar__inner').length > 0) {
                $('.sidebar__inner').fixTo('.right-column', {
                    mind: '#header',
                    top: 20
                });
            }

            if($('.addthis_inline_share_toolbox').length > 0) {
                $('.addthis_inline_share_toolbox').fixTo('.article-container', {
                    mind: '#header',
                    top: 20
                });
            }
        }
    }
}