export const team  = [
    {
        name: 'Vivek Couto',
        position: 'Executive Director & Co-founder',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit irstf.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis irstf',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'
    }, {
        name: 'Rupert Wilkinson',
        position: 'Executive Director & Co-founder',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit condse.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis condse.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'

    }, {
        name: 'Staff name',
        position: 'Vice President',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'

    }, {
        name: 'Staff name',
        position: 'Vice President',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'

    }, {
        name: 'Staff name',
        position: 'Vice President',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'

    }, {
        name: 'Staff name',
        position: 'Vice President',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'

    }, {
        name: 'Staff name',
        position: 'Vice President',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'

    }, {
        name: 'Staff name',
        position: 'Vice President',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'

    }, {
        name: 'Staff name',
        position: 'Vice President',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'

    }, {
        name: 'Staff name',
        position: 'Vice President',
        header: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.',
        image: 'avatar_default.jpg',
        subheader: '<span class="italic">Sed ut perspiciatis unde omnis iste natus</span> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.',
        content: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
            'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque',
        ],
        email: 'client@media-partners-asia.com',
        social: '/satffname'
    }
];