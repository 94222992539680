import $ from 'jquery';

export class FilterMenu {
    constructor() {
        this.menu = null;
        this.menuBtn;
        this.panel = null;
        this.isOpen = false;

        if($('.filterBar-menu').length > 0) {
            this.menu = $('.filterBar-menu');
            this.menuBtn = this.menu.find('.toggle-btn');
            this.panel = $('.research .views-container').find('.panel');
            this.onClickMenuBtn();
        }
    }

    onClickMenuBtn() {
        this.menu.click( (e) => {
            e.preventDefault();
            this.isOpen = !this.isOpen;
            this.toggleBar();
        })
    }

    toggleBar() {
        if(this.isOpen) {
            this.panel.addClass('open');
            this.menuBtn.removeClass('icon-expand').addClass('icon-collapse');
        }
        else {
            this.panel.removeClass('open');
            this.menuBtn.removeClass('icon-collapse').addClass('icon-expand');
        }
    }

    closeBar() {
        this.panel.removeClass('open');
        this.menuBtn.removeClass('icon-collapse').addClass('icon-expand');
    }
}