import $ from 'jquery';

export class Subscription {
    constructor() {
        this.subscribe = $('.subscription.not-processed');
        this.submitbtn = this.subscribe .find('.submit-btn');
        this.thankyou = $('.subscription.thank-you');

        if(this.submitbtn.length > 0) {
            this.form = $('.subscribe-form');
            this.onSubmitBtnClick();
        }
    }

    onSubmitBtnClick() {
        this.submitbtn.click( (e) => {
            e.preventDefault();
            if(this.validateForm()) {
                this.subscribe.css('display','none');
                this.thankyou.addClass('active');
            };
        });
    }

    validateForm() {
        let valid = true;
        if(!this.validateEmail()) {
            $('.invalid-message.email').addClass('active');
            valid = false;
        } else $('.invalid-message.email').removeClass('active');

        if(!this.validateFields('input','text', 'name')) {
            $('.invalid-message.name').addClass('active');
            valid = false;
        } else $('.invalid-message.name').removeClass('active');

        return valid;
    }

    validateEmail() {
        const value = this.form.find("input:text[name='email']").val();
        const valid =  value.length > 0 &&
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

        return valid;
    }

    validateFields(input, type, _name) {
        const field = this.form.find(input+"[name='"+_name +"']");
        const valid = (field !== null) ?field.val().length > 0 : false;
        return valid;
    }

}