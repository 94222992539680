import $ from 'jquery';

export class CookieBanner {
    constructor() {
        this.cookieBanner = $('.cookie-banner');

        const cookie = this.getCookie('mpa_cookies');
        if(!cookie) {
            this.cookieBanner.css('display','block');
        }

        this.cookieBanner.find('button').click( () => {
            event.preventDefault();
            this.setCookie('mpa_cookies', "accepted", 365);
            this.cookieBanner.fadeOut(300, () => {
                this.cookieBanner.css('display','none');
            });
        });
    }

    setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }


}