import $ from 'jquery';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import { CookieBanner } from './components/CookieBanner';
import { MenuPanel } from './components/MenuPanel';
import { Subscription } from './components/Subscription';
import { Landing } from './components/landing/Landing';
import { About } from './components/about/About';
import { Research } from './components/research/Research';
import { Consulting } from './components/consulting/Consulting';
import { Searchbar } from './components/searchbar/Searchbar';
import { Sticky } from './components/Sticky';
import { ScrollEvents } from './components/ScrollEvents';
import { backToTop } from './shared';
UIkit.use(Icons);

require('./../styles/styles.scss');


function onload() {
    init();
}

function init() {
    new Subscription();
    new MenuPanel();
    new Landing();
    new About();
    new Research();
    new Consulting();
    new CookieBanner();
    new Searchbar();
    new Sticky();
    new ScrollEvents();

    backToTop();
}



window.addEventListener('load', onload);