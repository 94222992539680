import { TeamPanel } from './TeamPanel';
import { BrandSlider } from './BrandSlider';
import { ContactForm } from './contact/ContactForm';
import { Map } from './contact/Map';

export class About {
    constructor() {
        new TeamPanel();
        new BrandSlider();
        new ContactForm();
        new Map();
    }
}