import $ from 'jquery';
import Masonry from 'masonry-layout'

import { detectmob } from "../../shared";

export class Card {
    constructor() {

        const isMobile = detectmob();

        if(!isMobile) {
            /**
             * Only apply Masonry for desktop
             */
            if($('.card-container').length > 0) {
                const grid = document.querySelector('.card-container');
                new Masonry(grid, {
                    itemSelector: '.card-tile',
                    percentPosition: true,
                    transitionDuration: 0
                });
            }

        }else {
            /**
             * Only show four cards on mobile, click other to show the rest
             */
            this.onClickLoadMore();
        }
    }

    onClickLoadMore() {
        const btn = $('.load-more-btn');
        btn.click( ()=> {
            $('.card-tile').addClass('show-all');
            btn.css('display','none');
        })
    }
}