import $ from 'jquery';
import {transitionEnd} from "../../../shared";

export class ContactForm {
    constructor() {
        this.form = null;
        this.submitbtn = $('.submit-btn');
        this.closeThankBtn = $('.close-thank-btn');
        if($('#contact-form').length > 0) {
            this.form = $('#contact-form');

            this.submitbtn.click( (e)=> {
                e.preventDefault();
                if(this.validateForm()) {
                    this.showEle($('.overlay'));
                    this.showEle($('.contact-thank-you'));
                };
            });

            this.closeThankBtn.click( (e) => {
                e.preventDefault();
                this.hideEle($('.overlay'));
                this.hideEle($('.contact-thank-you'));
                this.clearForm();
            })
        }
    }

    validateForm() {
        let valid = true;
        if(!this.validateEmail()) {
            $('.invalid-message.email').addClass('active');
            valid = false;
        } else $('.invalid-message.email').removeClass('active');

        if(!this.validateFields('input','text', 'name')) {
            $('.invalid-message.name').addClass('active');
            valid = false;
        } else $('.invalid-message.name').removeClass('active');

        if(!this.validateFields('input','text', 'company')) {
            console.log('validddd:', valid);
            $('.invalid-message.company').addClass('active');
            valid = false;
        } else $('.invalid-message.company').removeClass('active');

        if(!this.validateFields('input','text', 'job')) {
            $('.invalid-message.job').addClass('active');
            valid = false;
        } else $('.invalid-message.job').removeClass('active');

        if(!this.validateFields('textarea','text', 'message')) {
            $('.invalid-message.message').addClass('active');
            valid = false;
        }
        else $('.invalid-message.message').removeClass('active');

        return valid;
    }

    validateEmail() {
        const value = this.form.find("input:text[name='email']").val();
        const valid =  value.length > 0 &&
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

        return valid;
    }

    validateFields(input, type, _name) {
        const field = this.form.find(input+"[name='"+_name +"']");
        const valid = (field !== null) ?field.val().length > 0 : false;

        return valid;
    }

    showEle(element) {
        element.css('display','block');
        setTimeout( () => {
            element.addClass('active');
        }, 100);
    }

    hideEle(element) {
        element.removeClass('active');
        transitionEnd(element, () => {
            element.css('display','none');
        })
    }

    clearForm() {
        this.form.find("input:text").val('');
        this.form.find("textarea").val('');
        this.form.find("select").val('general');
    }
}