import $ from 'jquery';

import { transitionEnd } from '../shared';

export class MenuPanel {
    constructor() {
        this.hamburger = $('.hamburger');
        this.hamburgerIcon = this.hamburger.find('.icon');
        this.menuPanel = $('.menu-panel');
        this.sharebtn = $('.share-button');
        this.headerBarRight = $('#header-bar').find('.right');

        this.hamburger.click( () => {
            this.onMenuPanelToggle();
        });

        this.sharebtn.click( () => {
            console.log('share click');
            this.onSharePanelToggle();
        });

        this.initMenuItems();

    }
    onMenuPanelToggle() {
        if(this.menuPanel.hasClass('active')) {
            this.hamburgerIcon.removeClass('icon-close').addClass('icon-hamburgerMenu');
            this.menuPanel.removeClass('active');
            this.headerBarRight.removeClass('inactive');
        }else {
            this.menuPanel.addClass('active');
            this.hamburgerIcon.removeClass('icon-hamburgerMenu').addClass('icon-close');
            this.headerBarRight.addClass('inactive');
        }
    }

    initMenuItems() {
        const that = this;
        this.menu = this.menuPanel.find(' > ul > li');
        this.menu.each( function() {
            const submenu = $(this).find(' > ul');
            const menu = $(this);
            if( submenu.length > 0) {
                const height = parseInt(submenu.css('height'));
                submenu.css('height', height);
                $(this).addClass('icon icon-arrow-up');
                $(this).find(' > a ').click( function() {
                    that.onSubmenuToggle($(submenu),  menu);

                });
            }
        });
    }

    onSubmenuToggle(submenu, menu) {
        if(submenu.hasClass('collapse')) {
            $(menu).removeClass('icon-arrow-down').addClass('icon-arrow-up');
            $(submenu).css('display', 'block');
            setTimeout(function() {
                submenu.removeClass('collapse');
            },300);


        }else {
            submenu.addClass('collapse');
            $(menu).removeClass('icon-arrow-up').addClass('icon-arrow-down');

            transitionEnd(submenu, function() {
                $(submenu).css('display', 'none');
            });
        }
    }

    onSharePanelToggle() {
        const addthis =  $('.addthis_inline_share_toolbox');
        if(addthis.length > 0 && addthis.hasClass('active')) {
            addthis.removeClass('active');
            this.sharebtn.removeClass('active')
        }else {
            addthis.addClass('active');
            this.sharebtn.addClass('active')

        }
    }
}