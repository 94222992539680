import $ from 'jquery';
import { EventEmitter } from 'events';
import { detectmob, detectmobByWindowSize } from "./../../../shared";

export class FilterBar extends EventEmitter{
    constructor() {
        super();
        this.blocks = null;
        this.clearBtn = null;
        this.filterItemContainer = null;
        this.filterItems = [];


        const that = this;
        if($('.filter-bar').find('.block').length > 0) {
            this.blocks = $('.filter-bar').find('.block');
            this.blocks.each( function(index) {
                const block = $(this);
                const height =  parseInt(block.css('height'));
                block.css('height', height);

                /**
                 * Only open the first block on mobile
                 */
                if(detectmob() || detectmobByWindowSize()) {
                    if(index > 0) block.addClass('close');
                }

                that.toggleBlock(block);
                that.toggleIcon(block);
            });

            this.clearBtn = $('.clear-btn');
            this.onClickClearBtn(this.clearBtn);

            this.filterNowBtn = $('.filter-now-btn');
            this.onClickFilterNowBtn();

            this.filterItemContainer = $('.filter-item-container');
            this.onChangeCheckBox();
        }
    }

    toggleBlock(block) {
        const that = this;
        block.find('.top').click( function() {
            const block  = $(this).parent();
            if(block.hasClass('close')) block.removeClass('close');
            else block.addClass('close');
            that.toggleIcon(block);
        })
    }

    toggleIcon(block) {
        const icon = block.find('.toggle-btn');
        if(block.hasClass('close')) {
            icon.removeClass('icon-collapse').addClass('icon-expand');
        }else {
            icon.removeClass('icon-expand').addClass('icon-collapse');
        }
    }

    onClickClearBtn(button) {
        button.click( () => {
            $('input').each( function() {
                if($(this).prop('checked')) {
                    $(this).prop('checked', false)
                }
            });

            this.filterItems = [];
            this.updateFilterItemList();

        });
    }

    onClickFilterNowBtn() {
        this.filterNowBtn.click( () => {
            $(window).scrollTop(0);
            this.emit('onClickCloseBtn'); // eslint-disable-line no-invalid-this
        });
    }

    onChangeCheckBox() {
        const that = this;
        $('input').change(function() {
            if($(this).attr('type') === 'checkbox') {
                const name = $(this).attr('name');
                const value = $(this).val();
                if($(this).prop('checked')) {
                    that.addItem(name, value);
                }else {
                    that.filterItems = that.deleteItem(name);
                }
            }
            that.updateFilterItemList();
        });
    }

    updateCheckBox(name) {
        const checkbox = $("input:checkbox[name='" + name + "']");
        if(checkbox.prop('checked')) {
            checkbox.prop('checked', false);
        }
    }

    addItem(name, value) {
        this.filterItems.push({
            name: name,
            value: value
        });
    }

    deleteItem(name) {
        return this.filterItems.filter( ( item ) => item.name !== name);
    }

    updateFilterItemList() {
        console.log(this.filterItems);
        if(this.filterItems.length > 0) {
            const span = this.filterItems.map( (item) => {
                return `<span class="filter-item" data-name=${item.name}>${item.value}<span class="icon icon-close"></span></span>`;
            }).join('');
            this.filterItemContainer.html(span).append('<span class="clear-filter-btn">clear filters</span>');
            this.filterItemContainer.removeClass('inactive');
            this.onFilterItemClear();
            this.onClickClearBtn($('.clear-filter-btn'))
        }else {
            this.filterItemContainer.html('');
            this.filterItemContainer.addClass('inactive');
        }
    }

    onFilterItemClear() {
        const that = this;
        $('.filter-item').each( function(index) {
            $(this).click( function() {
                let name = $(this).data('name');
                if(!isNaN(name)) name = name.toString();
                that.filterItems = that.deleteItem(name);
                that.updateFilterItemList();
                that.updateCheckBox(name);
            })
        })
    }


}